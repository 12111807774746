import $storage from "@/utils/storage";
import { getHeaders } from "@/utils/fns";
import { BASE_URL, QNA_APIS } from "@/constants/api";
import api from "@/utils/api";

export default {
  addQuestion(params) {
    let url = BASE_URL() + QNA_APIS.add;
    if (params.questionId) {
      url += params.questionId;
    }
    return api
      .post(url, { ...params }, { headers: getHeaders() })
      .then((response) => {
        const {
          data: { success, result },
        } = response;
        if (success) {
          return result;
        }
        return new Error(data);
      });
  },
  getList(params) {
    const url = BASE_URL() + QNA_APIS.list;
    const LET_PASS = true;
    return api
      .get(url, { params, headers: getHeaders(LET_PASS) })
      .then((response) => {
        const {
          data: { success, result },
        } = response;
        if (success) {
          return Promise.resolve(result);
        }
        Promise.reject(data);
      })
      .catch((err) => Promise.reject(err));
  },
  getDetail(queId) {
    const url = BASE_URL() + QNA_APIS.list + queId;
    const LET_PASS = true;
    return api.get(url, { headers: getHeaders(LET_PASS) }).then((response) => {
      const {
        data: { success, result },
        data,
      } = response;
      if (success) {
        return result;
      }
      throw data;
    });
  },
  getQuestionTags(params) {
    return new Promise((resolve, reject) => {
      const tags = $storage.get("ques.tags");
      if (tags) {
        return resolve(tags);
      }
      const url = BASE_URL() + QNA_APIS.tags;
      const LET_PASS = true;
      return api
        .get(url, { params, headers: getHeaders(LET_PASS) })
        .then((response) => {
          const {
            data: { success, result },
            data,
          } = response;
          if (success) {
            $storage.set("ques.tags", result);
            return resolve(result);
          }
          reject(data || response);
        })
        .catch(reject);
    });
  },
};
