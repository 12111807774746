import { BASE_URL, RELATIVES_APIS } from "@/constants/api";
import api from "@/utils/api";
import { getHeaders } from "@/utils/fns";

const LET_PASS = true;
export default {
  async fetchRelativesList(userId) {
    const URL = BASE_URL() + RELATIVES_APIS.list + userId;
    const headers = getHeaders(LET_PASS);
    return api.get(URL, { headers }).then((json) => json.data);
  },
  async addOrUpdate(details) {
    let url = BASE_URL() + RELATIVES_APIS.add;
    const { relativeId } = details;
    if (relativeId) {
      url += relativeId;
    }
    const headers = getHeaders();
    return api
      .post(url, { ...details }, { headers })
      .then((response) => response.data);
  },
  async addFatihaRequest(relativeId, data) {
    const URL = BASE_URL() + RELATIVES_APIS.fatiha + relativeId;
    const headers = getHeaders();
    return api
      .post(URL, { ...data }, { headers })
      .then((response) => response.data)
      .then((json) => (json.success ? json.result : new Error(json)));
  },
  async deleteFatihaRequest(reqId) {
    const URL = BASE_URL() + RELATIVES_APIS.fatiha + reqId;
    const headers = getHeaders();
    return api.delete(URL, null, { headers }).then((response) => response.data);
  },
  //TODO: Test on relatives-list (again)
  deleteRelative(relativeId) {
    const URL = BASE_URL() + RELATIVES_APIS.list + relativeId;
    const headers = getHeaders();
    return api.delete(URL, null, { headers }).then((response) => response.data);
  },
};
