import api from "@/utils/api";
import { getHeaders } from "@/utils/fns";
import { BASE_URL, VENDOR_PATHS } from "@/constants/api";
import { MAPBOX_PUBLIC_TOKEN } from "@/constants/map";

const LET_PASS = true;

export default {
  //uses mapBox API on the BE. So, better use FE instead
  getMatchingPlaces(location) {
    const url =
      BASE_URL() + VENDOR_PATHS.map.basePath + VENDOR_PATHS.map.places;
    return api
      .get(url, { params: { location } })
      .then((response) => response.data);
  },

  getAddressFromLatLong(coords, options = {}) {
    return new Promise((resolve, reject) => {
      this.getReverseGeoCodeMapbox(coords, options)
        .then(resolve)
        .catch((error) => {
          console.warn("Error: ", error);
          this.getReverseGeoCodeGoogle(coords).then(resolve).catch(reject);
        });
    });
  },
  getReverseGeoCodeMapbox(coords, options = {}) {
    const location =
      typeof coords === "object"
        ? coords.longitude + "," + coords.latitude
        : coords;

    const { mapBox } = VENDOR_PATHS;
    const url = mapBox.basePath + mapBox.reverseGeocode + location + ".json";

    return new Promise((resolve, reject) => {
      api
        .get(url, {
          params: {
            types: options.type || "locality,place,district,region",
            country: options.countryCode || null, //looks for within this country only
            access_token: MAPBOX_PUBLIC_TOKEN,
          },
        })
        .then((response) => {
          const { data } = response;
          if (data) {
            const { features } = data;
            if (features && features.length) {
              const [first] = features;
              const res = {
                success: true,
                provider: "mapbox",
                result: {
                  formatted_address: first.place_name || first.text,
                  ...first,
                },
                results: features.map((item) => ({
                  ...item,
                  formatted: item.place_name || item.text,
                  latLong: item.center.join(","),
                })),
              };
              return resolve(res);
            }
            reject(data);
          }
        })
        .catch(reject);
    });
  },
  getReverseGeoCodeGoogle(coords, options = {}) {
    const { google } = VENDOR_PATHS;
    const latLong =
      typeof coords === "string"
        ? coords
        : coords.latitude + "," + coords.longitude;
    const url = google.basePath + google.city + latLong;
    return new Promise((resolve, reject) => {
      api
        .get(url, { params: options, headers: getHeaders(LET_PASS) })
        .then((response) => {
          const {
            data: { results },
          } = response;
          if (results) {
            const [first] = results;
            const res = {
              success: true,
              result: first,
              results,
              provider: "google",
            };
            return resolve(res);
          }
          reject(response);
        })
        .catch(reject);
    });
  },
  // uses timezone-db API to get timezone info for coords
  getCoordsTimezone(coords) {
    const latLong =
      typeof coords === "object"
        ? [coords.latitude, coords.longitude].join(",")
        : coords;

    const { map } = VENDOR_PATHS;
    const url = map.basePath + map.timezone + latLong;
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((json) => {
          const { data } = json || {};
          const { success, result } = data;
          if (success) {
            console.log("timezone result: ", result);
            return resolve(result);
          }
          reject(data);
        })
        .catch(reject);
    });
  },
};
