import { setupI18n } from "./i18n";
import { createApp, ref } from "vue";
import { router } from "./routes";
import App from "./App.vue";
import VueGtag from "vue-gtag";
import * as Sentry from "@sentry/vue";

import {
  IonicVue,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonItem,
  IonLabel,
  IonIcon,
  IonNote,
  IonText,
  IonButton,
  IonPage,
  IonContent,
  IonFooter,
  IonToolbar,
  IonSpinner,
  IonProgressBar,
  IonImg,
  isPlatform,
} from "@ionic/vue";
// for AdSense Compoent
import ScriptX from "vue-scriptx";
import Ads from "vue-google-adsense";
// End: Adsense
import AdBlock from "@/components/Ads/AdBlock.vue";
import PageNavBar from "@/components/Nav/PageNavBar.vue";
import ModalNavBar from "@/components/Nav/ModalNavBar.vue";
import $storage from "@/utils/storage";

import "@/styles.js";

import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { SQUARE, TRACK_THIS, TEXT_IMG, OPEN_URL } from "@/directives";

if (isPlatform("desktop") || isPlatform("mobileweb")) {
  require("./registerServiceWorker");
}

const $moamin = ref({ features: require("../public/assets/ssr-features") });

const app = createApp(App);

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    app,
    dsn: "https://ea6a4297096e498d8332060098890077@o4505292966068224.ingest.sentry.io/4505292967575552",
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/api\.bazm\.in/,
          "https://api.moam.in",
        ],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      // new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app
  .use(IonicVue, {
    backButtonText: "", // disable text in 'back-btn' on iOS
    swipeBackEnabled: true,
  })
  .use(router)
  //vue-gtag documentation: https://matteo-gabriele.gitbook.io/vue-gtag/custom-installation
  .use(
    VueGtag,
    {
      bootstrap: false,
      enabled: false, //enable after cookie banner
      // disableScriptLoad: true,
    },
    router
  )
  .use(setupI18n())
  .use({
    install: (app, options) => {
      app.config.globalProperties.$moamin = $moamin.value;
    },
  })
  .use(ScriptX)
  .use(Ads.Adsense)
  .use(Ads.InArticleAdsense)
  .use(Ads.InFeedAdsense)

  .component("ad-block", AdBlock)
  .component("page-nav-bar", PageNavBar)
  .component("modal-nav-bar", ModalNavBar)

  .component("ion-page", IonPage)
  .component("ion-footer", IonFooter)
  .component("ion-toolbar", IonToolbar)
  .component("ion-content", IonContent)
  .component("ion-item", IonItem)
  .component("ion-img", IonImg)
  .component("ion-icon", IonIcon)
  .component("ion-label", IonLabel)
  .component("ion-button", IonButton)
  .component("ion-card", IonCard)
  .component("ion-card-header", IonCardHeader)
  .component("ion-card-content", IonCardContent)
  .component("ion-spinner", IonSpinner)
  .component("ion-progress-bar", IonProgressBar)

  .directive("track-this", TRACK_THIS)
  .directive("text-img", TEXT_IMG)
  .directive("open-url", OPEN_URL)
  .directive("square", SQUARE);

import mitt from "mitt";
app.config.globalProperties.$events = mitt();

$storage.setup(); // does the migration too

router.isReady().then(() => {
  const body = document.querySelector("body")!; // ! sign to fix 'Object is possibly null' error
  body.classList.remove("loading")!; // remove loading-animation
  app.mount("#app");
  defineCustomElements(window); //required for pwa-web-components (eg, for camera plugin)
});
