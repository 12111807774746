import $storage from "@/utils/storage";
import { getHeaders } from "@/utils/fns";
import {
  BASE_URL,
  USER_APIS,
  MISC_APIS,
  AUTH_PATHS,
  FOLLOW_APIS,
} from "@/constants/api";
import { getUser } from "@/composables";
import api from "@/utils/api";

const LET_PASS = true;
const { currentUser } = getUser();

export default {
  pageViews: {
    keyName: "pageviews",
    maxTracking: {},
    max: 5,
    track: function (pageName, section = null) {
      const tracked = $storage.get(this.keyName, {});
      let count = this.getViews(pageName, section);
      const maxTracking = this.maxTracking[pageName]
        ? this.maxTracking[pageName]
        : this.max;
      if (section) {
        tracked[pageName] = tracked[pageName] || {};
        tracked[pageName][section] = count <= maxTracking ? ++count : count;
      } else {
        tracked[pageName] = count <= maxTracking ? ++count : count;
      }
      $storage.set(this.keyName, tracked);
    },
    getViews: function (pageName, section = null) {
      const tracked = $storage.get(this.keyName, {});
      if (tracked[pageName]) {
        const page = tracked[pageName];
        return section ? page[section] || 0 : page || 0;
      }
      return 0;
    },
    setMax: function (pageName, val) {
      this.maxTracking[pageName] = val;
    },
  },
  // also used by fns below
  profile: {
    // @params is optional, for cases when additional query-params are required to be sent
    fetch(userId, isGuestUser, params) {
      const URL = BASE_URL() + AUTH_PATHS.profile + userId;
      const LET_PASS = isGuestUser;
      const headers = getHeaders(LET_PASS);
      return api
        .get(URL, { params, headers })
        .then((response) => response.data);
    },
    update(data, section, tempToken) {
      let url = USER_APIS.profile;
      if (section) {
        url += "/" + section;
      }
      const headers = {};
      // because this allows updating any user's info with a temp token, ensure that it doesn't breach security
      if (tempToken) {
        headers.token = tempToken;
        headers.is_guest = 1;
      }
      return api
        .post(BASE_URL() + url, data, { headers })
        .then((response) => response.data);
    },
    findUserId(forUsername) {
      const URL = USER_APIS.userId + forUsername;
      return api.get(BASE_URL() + URL).then((response) => response.data);
    },
    toggleFollow(userId, unfollow) {
      const URL =
        (unfollow ? FOLLOW_APIS.unfollow : FOLLOW_APIS.follow) + userId;
      return api
        .post(BASE_URL() + URL, {}, { headers: getHeaders() })
        .then((response) => response.data);
    },
    getFollowings(userId, params) {
      const URL = FOLLOW_APIS.list + userId;
      // console.log("params: ", params);
      return api
        .get(BASE_URL() + URL, { params, headers: getHeaders(LET_PASS) })
        .then((response) => response.data);
    },
    toggleFollowRequest(action, fromUserId, additional) {
      const URL = FOLLOW_APIS.list + `${action}/` + fromUserId;

      return api
        .post(BASE_URL() + URL, additional, { headers: getHeaders() })
        .then((response) => response.data);
    },
  },
  updateSingleField(fieldName, data) {
    const URL = USER_APIS.update_single + fieldName;
    return api
      .post(BASE_URL() + URL, data, { headers: getHeaders() })
      .then((response) => response.data);
  },
  getProfile(userId, cached, moreParams) {
    return new Promise((resolve, reject) => {
      const my = currentUser();
      if (!userId) {
        userId = my.userId;
        if (!userId) {
          reject({
            code: "INVALID_REQUEST",
            message: "UserId missing in request",
          });
        }
      }
      const isMyProfileRequest = userId === my.userId; // works only for logged-in users
      if (cached && isMyProfileRequest) {
        const cachedProfile = $storage.get("profile." + userId);
        if (cachedProfile) {
          resolve(cachedProfile);
          return;
        }
      }
      const guestUser = !Boolean(my.userId);
      this.profile
        .fetch(userId, guestUser, moreParams)
        .then((json) => {
          const { success, result, otpResult } = json;
          if (success) {
            const { userObj } = result;
            if (userObj) {
              delete result.userObj;
            }

            if (isMyProfileRequest) {
              // saveUser(userObj);
              $storage.set("profile." + userId, result);
            }
            if (otpResult) {
              result._ = { otpResult }; // since `anything._` is a debug key across app
            }
            return resolve(result);
          }
          reject(json);
        })
        .catch((error) => {
          const err = error && error.data ? error.data : error;
          console.warn("error in fetchProfile:", err);
          reject(err);
        });
    });
  },
  getBookMarks(name) {
    return new Promise((resolve, reject) => {
      const userId = this.getCurrent({}).userId;
      if (!userId) {
        setTimeout(() => reject("User not authorised"));
      } else {
        $pouchdb
          .getDoc(`user_${userId}`)
          .then((doc) => {
            const { bookmarks } = doc;
            if (!bookmarks) {
              return resolve([]);
            }
            resolve(bookmarks[name] || []);
          })
          .catch(reject);
      }
    });
  },
  setBookMarks(name, values) {
    return new Promise((resolve, reject) => {
      const userId = this.getCurrent({}).userId;
      if (!userId) {
        setTimeout(() => reject("User not authorised"));
      } else {
        const onHasDoc = (doc) => {
          doc.bookmarks = {
            ...doc.bookmarks,
            [name]: values,
          };
          $pouchdb.put(doc).then(resolve).catch(reject);
        };
        $pouchdb
          .getDoc(`user_${userId}`)
          .then(onHasDoc)
          .catch((err) => {
            if (err.status === 404) {
              return onHasDoc({
                _id: `user_${userId}`, //create new doc. _id is mandatory for that
                bookmarks: {},
              });
            }
            reject(err);
          });
      }
    });
  },
  // used in forgot-pwd
  getTemporaryToken(params) {
    const phone = params.mobile.replace(/^\+/, "").trim();
    const URL =
      BASE_URL() +
      AUTH_PATHS.guestToken +
      `?phone=${phone}&expiresIn=${params.expiresIn || 300}`;
    return api.get(URL).then((response) => response.data);
  },
  inviteGuests(params, actionAfterInvite) {
    const URL = BASE_URL() + USER_APIS.invite + (actionAfterInvite || "");
    return api.post(URL, params).then((response) => response.data);
  },
  verifyGuestToken(match, value, token) {
    const URL = BASE_URL() + AUTH_PATHS.guestToken + `?${match}=${value}`;
    const headers = { token, is_guest: 1 };
    return api.post(URL, {}, { headers }).then((response) => response.data);
  },
  sendResetEmail(email, oldEmail = null) {
    const URL = BASE_URL() + AUTH_PATHS.resetEmail;
    return api.post(URL, { email, oldEmail }).then((response) => response.data);
  },
  verifyEmail(token) {
    const URL = BASE_URL() + AUTH_PATHS.verifyEmail + "?emailToken=" + token;
    return api.post(URL).then((response) => response.data);
  },
  sendVerificationLinkEmail(email) {
    const URL = BASE_URL() + AUTH_PATHS.verificationLinkEmail;
    return api.post(URL, { email }).then((response) => response.data);
  },
  verifyShiaSadat(params, rejected) {
    const URL =
      BASE_URL() + AUTH_PATHS.verifyShiaSadat + (rejected ? "/rejected" : "");
    return api.post(URL, params).then((response) => response.data);
  },

  setSharingPreferences(dataObj) {
    $storage.set("pref.sharing", dataObj);
  },
  getSharingPreferences(useDefault) {
    let def = {};
    if (useDefault) {
      def = {
        useName: true,
        signature: currentUser().fullName,
      };
    }
    return $storage.get("pref.sharing", def);
  },
  getExternalShareToken() {
    const URL = BASE_URL() + AUTH_PATHS.tokenExternal;
    return api.get(URL).then((response) => response.data);
  },
  getNotifications(params) {
    const URL = BASE_URL() + MISC_APIS.notifications;
    return api.post(URL, params).then((response) => response.data);
  },
  updateNotification(id, data) {
    const URL = BASE_URL() + MISC_APIS.notifications + `/${id}`;
    return api.post(URL, data).then((response) => response.data);
  },
  // this fn is here because only '$user' was in routes.js (API is still at path /other)
  getRedirectUrl(forPath) {
    const URL = BASE_URL() + MISC_APIS.redirect + forPath;
    return api.get(URL).then((response) => response.data);
  },
};
