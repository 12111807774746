import api from "@/utils/api";
import { BOT_API } from "@/constants/api";
import { getHeaders } from "@/utils/fns";

export default {
  getDiscussionListOrDetail(id = null) {
    const URL = BOT_API.conversation + (id ? id : "");
    let headers;
    if (id) {
      headers = getHeaders(true);
    }
    return api.get(URL, { headers }).then(({ data }) => data);
  },
  postNewQuestion(params) {
    const URL = BOT_API.askQuestion;
    return api.post(URL, params).then(({ data }) => data);
  },
  getCreditPrice() {
    const URL = BOT_API.price;
    return api.get(URL).then(({ data }) => data);
  },
};
