import $storage from "@/utils/storage";
import api from "@/utils/api";
import { Device } from "@capacitor/device";
import { VENDOR_PATHS } from "@/constants/api";
import { PUSH_APIS } from "@/constants/api";
import hashObject from "object-hash";

export default {
  // copied from bazm-v2
  openDeviceCamera(constraints) {
    navigator.getMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia ||
      (navigator.mediaDevices && navigator.mediaDevices.getUserMedia);

    return new Promise((resolve, reject) =>
      navigator.getMedia(constraints, resolve, reject)
    );
  },
  // copied from bazm-v2
  getMediaRecorder(stream) {
    // const mediaSource = new MediaSource();
    // let sourceBuffer;
    // mediaSource.addEventListener('sourceopen', e => {
    //   sourceBuffer = mediaSource.addSourceBuffer('video/webm; codecs="vp8"');
    // }, false);
    const bitsPerSecond = 1000000; //IMbps (default: 2.5 Mbps)
    var options = { mimeType: "video/webm", bitsPerSecond };
    let mediaRecorder,
      supported = true;
    try {
      mediaRecorder = new MediaRecorder(stream, options);
    } catch (e0) {
      console.log("Unable to create MediaRecorder with options Object: ", e0);
      try {
        options = { mimeType: "video/webm,codecs=vp9", bitsPerSecond };
        mediaRecorder = new MediaRecorder(stream, options);
      } catch (e1) {
        console.log("Unable to create MediaRecorder with options Object: ", e1);
        try {
          options = "video/vp8"; // Chrome 47
          mediaRecorder = new MediaRecorder(stream, options);
        } catch (e2) {
          console.error("Exception while creating MediaRecorder:", e2);
          // gl.saveSupported = false;
          supported = false;
        }
      }
    }
    if (supported !== false) {
      console.info("Recording supported: ", mediaRecorder);
      // $scope.$applyAsync();
      return mediaRecorder;
    } else {
      console.error("Recording not supported");
    }
    return mediaRecorder;
  },
  async getDefaultLocation() {
    const geo = this.getCurrentGeo(); // gives 'coords' (with keys 'lat', 'lng'), and additional data
    if (geo) return geo;

    try {
      const loc = await this.getGeoDataFromIP(true);
      const { coords } = loc;
      return {
        ...coords, //gives keys lat, lng
        ...loc,
        loc_type: "ip",
      };
    } catch (e) {
      const alarmLoc = $storage.get("alarms.automatic"); // revert to using alarm location
      if (alarmLoc) {
        alarmLoc.loc_type = "alarm";
      }
      return alarmLoc;
    }
  },
  getCurrentGeo() {
    const val = $storage.get("location.geo_current", null);
    if (val) {
      val.loc_type = "ip";
    }
    return val;
  },
  setCurrentGeo(locObj) {
    $storage.set("location.geo_current", locObj);
  },
  getGeoDataFromIP(update = false) {
    // const URL = VENDOR_PATHS.geoplugin.getGeoDataFromIP;
    const URL = VENDOR_PATHS.geoplugin.ipgeolocation;
    return api.get(URL, { headers: { "X-CORS": 1 } }).then((response) => {
      const { data } = response;
      if (URL === VENDOR_PATHS.geoplugin.getGeoDataFromIP) {
        Object.keys(data).map((key) => {
          data[key.replace("geoplugin_", "")] = data[key];
          delete data[key];
        });
        delete data.credit;
        delete data.status;
      } else {
        data.timezone = data.time_zone.name;
        data.currencyName = data.currency.name;
        data.currencySymbol = data.currency.symbol;
        data.currencyCode = data.currency.currencyCode;
        delete data.currency;
        data.region = data.state_prov;
        delete data.state_prov;
        Object.keys(data).forEach((key) => {
          if (key.match(/_[a-z]/)) {
            const newKey = key.replace(
              /_[a-z]/g,
              // skips char@0, and converts everythign to ucfirst
              (match) => match.slice(1, 2).toUpperCase() + match.slice(3)
            );
            data[newKey] = data[key];
            delete data[key];
          }
        });
      }
      const geoLocation = {
        ...data,
        coords: {
          lat: data.latitude,
          lng: data.longitude,
        },
      };
      delete geoLocation.latitude;
      delete geoLocation.longitude;
      if (update) {
        this.setCurrentGeo(geoLocation);
      }
      return geoLocation;
    });
  },
  async getDeviceID() {
    return await Device.getId();
  },
  async getDeviceInfo() {
    const { uuid } = await this.getDeviceID();
    const info = await Device.getInfo();
    const deviceModel = [info.manufacturer, info.model]
      .filter((i) => !!i)
      .join(" ");
    const deviceId = hashObject({ uuid, deviceModel }); // ensures more uniqueness

    return {
      ...info,
      uuid,
      deviceId,
      deviceModel,
      deviceType: info.platform,
      deviceOS: info.operatingSystem,
    };
  },
  async registerPushToken({ playerId, pushToken }) {
    const deviceInfo = await this.getDeviceInfo();
    // example:
    // { "playerId": "0d5bc619-d712-401e-8f01-5a32d7aa2fc7", "deviceId": "13cc096cc0df4b94", "memUsed": 6229632, "diskFree": 0, "diskTotal": 3331153920, "realDiskFree": 207237066752, "realDiskTotal": 242938245120, "model": "M2102J20SG", "operatingSystem": "android", "osVersion": "12", "platform": "android", "manufacturer": "Xiaomi", "isVirtual": false, "name": "M2102J20SG", "webViewVersion": "110.0.5481.153", "pushToken": "dcn9j_xBQw6Y9V0GJUBdaO:APA91bFk4oJyWkie8io8b4ypaZA7IETKkqA-dhY28PD6fPs_n4APegiWfxlUhleWKFRRCyelR5PAUaR8HJaY2Hg0edfG_4J21AZK_GmMtrAn9SDyLsclgTc9rSmCW_NKPLsSIAmCcv4k" }
    return api
      .post(PUSH_APIS.registerPushToken, {
        playerId,
        device: deviceInfo,
        pushToken,
      })
      .then(({ data }) => {
        console.log("Device registered: ", data);
        Promise.resolve(data);
      })
      .catch((err) => Promise.reject(err));
  },
  async registerBrowser(subscription) {
    const deviceInfo = await this.getDeviceInfo();
    return api
      .post(PUSH_APIS.registerBrowser, { subscription, device: deviceInfo })
      .then(({ data }) => Promise.resolve(data))
      .catch(({ data }) => {
        console.warn("Failed to subscribe: ", data);
        Promise.reject({ error: data, code: "apiError" });
      });
  },
};
