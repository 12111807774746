import { BASE_URL, AUTH_PATHS } from "@/constants/api";
import { getUser } from "@/composables";
import { getHeaders } from "@/utils/fns";

import $storage from "@/utils/storage";
import api from "@/utils/api";

const LET_PASS = true;
const { currentUser } = getUser();

export default {
  reloadUser(userId) {
    const URL = BASE_URL() + AUTH_PATHS.reloadUser + userId;
    const headers = getHeaders(!LET_PASS);

    return api.get(URL, { headers }).then(({ data }) => this.saveUser(data));
  },
  loginNormal(credentials, additional = {}) {
    const URL = BASE_URL() + AUTH_PATHS.login;
    const headers = getHeaders(!LET_PASS, additional);
    const { remember } = credentials;
    delete credentials.remember;
    return api.post(URL, { ...credentials }, { headers }).then((response) => {
      const { data } = response || {};
      const { success, result } = data;
      if (success) {
        this.saveUser(data);
        const user = { ...result };
        remember && this.rememberAccount(user);
        return user;
      }
      return new Error(data);
    });
  },
  loginSocial(platform, credentials) {
    const URL = BASE_URL() + AUTH_PATHS.login + AUTH_PATHS.social + platform;
    const headers = getHeaders();
    return api.post(URL, { ...credentials }, { headers }).then((response) => {
      const { data } = response || {};
      if (data.success) {
        return this.saveUser(data);
      }
      return new Error(response);
    });
  },
  loginViaOtp(params) {
    const URL = BASE_URL() + AUTH_PATHS.otpLogin;
    const headers = getHeaders();
    return api.post(URL, params, { headers }).then((response) => {
      const { data } = response || {};
      if (data.success) {
        return this.saveUser(data);
      }
      throw response;
    });
  },
  registerUser(userObj) {
    const URL = BASE_URL() + AUTH_PATHS.register;
    return api.post(URL, { ...userObj }).then((response) => {
      const { data } = response || {};
      const { success, result, token } = data || {};
      if (success) {
        this.saveUser({ user: result, token });
        return result;
      }
      return new Error(response);
    });
  },
  logoutUser() {
    const URL = BASE_URL() + AUTH_PATHS.logout;
    const user = currentUser();
    $storage.delete("user");
    // $storage.delete('profile.' + user.userId);
    $storage.delete("token");
    const { userId } = user;
    const deviceId = ""; //TODO: Add deviceToken here
    return api
      .post(URL, { userId, deviceId })
      .then((response) => response.data);
  },
  rememberAccount(userObj, remove) {
    const accounts = $storage.get("user.accounts", []);
    let findIndex = accounts.findIndex((user) => user.email === userObj.email);
    if (!remove) {
      if (findIndex < 0 && !remove) {
        accounts.push({ userId: userObj.userId, email: userObj.email });
        findIndex = accounts.length - 1;
      }
      accounts[findIndex].lastLogin = Date.now();
    } else {
      accounts.splice(findIndex, 1);
    }
    $storage.set("user.accounts", accounts);
    return accounts;
  },
  // token is temp-token to override current user (eg, 2-step verification)
  sendOTP(viaApp, toMobile, token = null) {
    const URL =
      BASE_URL() + AUTH_PATHS.otpSend + toMobile + "/" + (viaApp || "sms");
    return api
      .post(URL, {}, { headers: getHeaders(!LET_PASS, { token }) })
      .then((response) => response.data);
  },
  verifyOTP({ mobile, otp, appName }) {
    const URL = BASE_URL() + AUTH_PATHS.otpVerify;
    return api
      .post(URL, { mobile, otp, appName }, { headers: getHeaders(!LET_PASS) })
      .then((response) => {
        const { data } = response || {};
        if (data.success) {
          this.saveUser(data);
          return data;
        }
        return new Error(data);
      });
  },
  saveUser(dataObj) {
    const { result, token, user } = dataObj; //dataObj could be {result: {user}, token: string} or, {user}
    const userObj = user || result;

    userObj.hasPassword = userObj.userPassword === "HAS";
    delete userObj.password;
    delete userObj.salt;

    $storage.set("user", userObj);

    token && $storage.set("token", token);
    return userObj;
  },
};
