import { shiaAdhaans, dateLabels } from "@/constants/pages/alarm";
import { VENDOR_PATHS } from "@/constants/api";
import { getHeaders } from "@/utils/fns";
import { getUser } from "@/composables";
import { $device } from "@/apis";
import { LocalNotifications as $LN } from "@capacitor/local-notifications";
import api from "@/utils/api";
import $storage from "@/utils/storage";

const LET_PASS = true;
const promises = {};

export default {
  $LN,
  schedule(list) {
    list.forEach(
      (i) =>
        (i.channel = i.channel || {
          id: i.title,
          name: i.body,
          description: i.summaryText,
          sound: i.sound,
          importance: 5,
          visibility: 1,
        })
    );
    // console.log('here')
    return new Promise((resolve, reject) => {
      const notifications = [...list];
      $LN
        .checkPermissions()
        .then(({ display }) => {
          console.log("granted permissions:", display);
          const granted = display === "granted";
          if (!granted) {
            $LN
              .requestPermissions()
              .then((perms) => {
                console.log("requested permissions: ", perms);
                if (perms === true) {
                  $LN.schedule({ notifications }).then(resolve);
                  return;
                }
                reject(perms);
              })
              .catch(reject);
          } else {
            $LN.schedule({ notifications }).then(resolve);
          }
        })
        .catch(reject);
    });
  },
  isAutomaticOn() {
    return Number($storage.get("alarms.automatic") || "0");
  },
  setAutomaticFlag(value) {
    $storage.set("alarms.automatic", value);
  },
  getAlarmSettings(field) {
    const GLOBAL = true;
    const alarmSettings = $storage.get("alarm.settings", null, false, GLOBAL);
    const params = {
      sounds: [],
      alarmSound:
        alarmSettings && alarmSettings.sounds && alarmSettings.sounds[0],
      adhanSound: shiaAdhaans[0],
      calculationMethod: 0,
      ...alarmSettings,
    };
    if (field === "method") {
      return params.calculationMethod;
    }
    return params;
  },
  setAlarmSettings(name, value) {
    const settings = this.getAlarmSettings();
    settings[name] = value;

    const GLOBAL = true;
    $storage.set("alarm.settings", settings, GLOBAL);
  },
  getCachedCalendar() {
    return $storage.get("calendar", null);
  },
  getCalendar(params) {
    const cachedCalender = this.getCachedCalendar();
    const thisMonth = dateLabels.months[new Date().getMonth()];
    const { coords } = params;
    promises.getCalendar =
      promises.getCalendar ||
      new Promise((resolve, reject) => {
        const doCalendarFetching = () => {
          return this.fetchAlarmTimingCalendar(params)
            .then((json) => {
              const { calendar, alarmSounds } = json;
              //NOTE: alarms can be json response or json data, depending upon backend status
              const data = {
                month: thisMonth,
                location: coords,
                detail:
                  typeof calendar.data !== "undefined"
                    ? calendar.data
                    : calendar,
              };
              if (alarmSounds) {
                this.setAlarmSettings("sounds", alarmSounds);
              }
              if (data.detail) {
                const monthCalendar = [...data.detail];
                monthCalendar.forEach((Day) => {
                  if (!Day.timestamps) {
                    Day.timestamps = {};
                    for (const key in Day.timings) {
                      const value = Day.timings[key];
                      const ts = Day.date.timestamp * 1000; // *1000, since timestamp is Unix timestamp, not JS timestamp
                      const d = new Date(ts);
                      const temp = value.split(" ")[0].split(":");
                      d.setHours(parseInt(temp[0]));
                      d.setMinutes(parseInt(temp[1]));
                      Day.timestamps[key] = d.getTime();
                    }
                    if (Day.timestamps.Maghrib === Day.timestamps.Sunset) {
                      Day.timestamps.Maghrib += 20 * 60 * 1000; //add 20 minutes
                    }
                  }
                });
                data.detail = [...monthCalendar];
              }
              $storage.set("calendar", data);
              resolve(data.detail);
            })
            .catch(reject)
            .finally(() => setTimeout(() => delete promises.getCalendar, 100));
        };

        if (cachedCalender) {
          const { month, location } = cachedCalender;
          if (month !== thisMonth || location !== coords) {
            $storage.delete("calendar"); //* remove calendar for expired month/location
            return doCalendarFetching(params);
          }
          setTimeout(() => delete promises.getCalendar, 100);
          return resolve(cachedCalender);
        }
        //TODO: Add location calculation from current coords to save coords and call API again if needed
        doCalendarFetching(params);
      });
    return promises.getCalendar;
  },
  fetchAlarmTimingCalendar(params) {
    const { coords } = params;
    const lat = coords.latitude || coords.lat;
    const lng = coords.longitude || coords.lng;
    const { alAdhan } = VENDOR_PATHS;

    const baseUrl = alAdhan.basePath;
    const query = ["longitude=" + lng, "latitude=" + lat];
    if (params.timezone) {
      query.push("timezone=" + params.timezone);
    }
    if (params.calculationMethod !== undefined) {
      query.push("method=" + params.calculationMethod);
    }
    const url = baseUrl + alAdhan.calendar + "?" + query.join("&");
    const headers = getHeaders(LET_PASS);
    return api.get(url, { headers }).then((response) => {
      const { data } = response;
      console.log("alarm-data: ", data);
      return data && data.success ? data : new Error(data);
    });
  },
  setAlarmLocation(location) {
    $storage.set("location.alarm", location);
  },
  /*
  - Gets User-Saved location (not User's city) first
  - Get User's city, in case user hasn't saved any locations
  - Gets approximate location (using IP) in case nothing is available
   */
  getAlarmLocation() {
    const { isGuest, currentUser } = getUser();
    promises.location =
      promises.location ||
      new Promise((resolve, reject) => {
        // alarm location (saved by guest/logged-in user under same key)
        const location = $storage.get("location.alarm", null);
        const hasCachedLocation = location && Object.keys(location).length;
        if (hasCachedLocation) {
          return resolve(location);
        } else {
          if (!isGuest()) {
            const { cityName, countryName, countryCode } = currentUser();
            if (cityName && countryName) {
              return resolve({
                city: cityName,
                countryCode,
                countryName,
              });
            }
          }
          $device
            .getGeoDataFromIP(true)
            .then((geoData) => {
              console.log("got geo-data from IP: ", geoData);

              resolve(geoData);
              this.setAlarmLocation(geoData);
            })
            .catch((err) => {
              console.warn("getAlarmLocation.getGeoDataFromIP error: ", err);
              // TODO: Check if this overrides user setting on failure of above api
              const geo = $device.getCurrentGeo();
              geo ? resolve({ ...geo }) : reject(err);
            })
            .finally(() => setTimeout(() => delete promises.location, 100));
        }
      });
    return promises.location;
  },
  // TODO: test this for all cases
  isRamadanClose() {
    return new Promise((resolve, reject) => {
      // const daysToRamadan = $storage.get('daysToRamadan', null);
      // daysToRamadan && daysToRamadan.days <= 3 ? resolve(daysToRamadan) : null;
      this.getAlarmLocation()
        .then((location) => {
          let { coords } = location;
          if (!coords) {
            const { latitude, longitude, latLong } = location;
            if (latLong) {
              const temp = latLong.split(",");
              coords = {
                latitude: temp[0],
                longitude: temp[1],
              };
            } else if (latitude && longitude) {
              coords = {
                latitude,
                longitude,
              };
            } else {
              return reject("Could not determine your location.");
            }
          }
          const calculationMethod = this.getAlarmSettings("method");
          const params = {
            coords,
            calculationMethod,
          };
          this.getCalendar(params)
            .then((calendar) => {
              console.log(
                "isRamadanClose > calendar: ",
                calendar,
                typeof calendar
              );
              const day = new Date().getDate() - 1; //since coords array index starts at 0
              const dayDetails = calendar[day];
              const daysLeftInMonth = 30 - Number(dayDetails.date.hijri.day);
              // const urduMonth = dayDetails.date.hijri.month.en;//english name
              const monthNumber = dayDetails.date.hijri.month.number; //shaban is 8th Month of urdu calendar
              const monthsRemaining = 9 - monthNumber;
              const days =
                (monthNumber > 9
                  ? (12 - (monthNumber + 1) + 9) * 30
                  : (monthsRemaining - 1) * 30) + daysLeftInMonth;
              $storage.set("daysToRamadan", { days, updated: Date.now() }); //remaining days in ramandan
              //Shaban is 8th month, Ramadan is 9th
              if ([8, 9].includes(monthNumber)) {
                const isRamadanClose = monthNumber === 8 && days <= 3;
                return isRamadanClose || monthNumber === 9
                  ? resolve(days)
                  : reject(days);
              }
              reject(days);
            })
            .catch(reject);
        })
        .catch(reject);
    });
  },
};
