import { useTracker, getUser } from "@/composables";

const { getTextAvatar } = getUser();

const TRACK_THIS = {
  mounted(el, binding) {
    const {
      value: { category, label, value, event },
    } = binding;
    if (event) {
      const { Analytics } = useTracker();
      el.addEventListener(event, () => {
        console.log("%s triggered", event);
        Analytics("event", { action: event, event, category, label, value });
      });
    }
  },
  unmounted(el, binding) {
    const {
      value: { event },
    } = binding;
    el.removeEventListener(event, () => {
      console.log("event removed");
    });
  },
};

const SQUARE = function (el) {
  setTimeout(() => {
    // console.log('el updated: ', el.offsetWidth)
    el.style.minHeight = el.offsetWidth + "px";
  }, 500);
};
const onLinkClick = function () {
  const url = this.getAttribute("data-url"); //this refers to el (since not using arrow fn)
  if (url) {
    window.open(url, "_system");
    // TODO: Add logging for analytics purpose
  }
};
const OPEN_URL = {
  mounted(el) {
    el.addEventListener("click", onLinkClick);
  },
  unmounted(el) {
    el.removeEventListener("click", onLinkClick);
  },
};

const TEXT_IMG = (el, binding) => {
  const { value } = binding;
  if (value) {
    let params = {};
    if (typeof value === "string") {
      params.userName = value;
    } else {
      params = { ...value };
    }
    const { userName } = params;
    if (!userName) return;
    params.userName = params.userName.replace("//", "");
    if (userName.toLowerCase() === "you") {
      params.length = 3;
    }
    el.onerror = () => {
      console.log("Image failed to load: ", el.src);
      el.src = getTextAvatar(params);
    };
    if (!el.src) {
      el.src = getTextAvatar(params);
    }
  }
};

export { SQUARE, TRACK_THIS, OPEN_URL, TEXT_IMG };
